// Generated by Framer (78a4586)

import { addFonts, cx, CycleVariantState, getFontsFromSharedStyle, Image, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/EDrqc2B6F";

const enabledGestures = {nWQBGlGSq: {hover: true}};

const cycleOrder = ["nWQBGlGSq"];

const serializationHash = "framer-FSkOl"

const variantClassNames = {nWQBGlGSq: "framer-v-11xeq2l"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "nWQBGlGSq", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (gestureVariant === "nWQBGlGSq-hover") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-11xeq2l", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"nWQBGlGSq"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"nWQBGlGSq-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FzdG9ucG9saXogUmVndWxhcg==", "--framer-font-family": "\"Astonpoliz Regular\", \"Astonpoliz Regular Placeholder\", sans-serif", "--framer-font-size": "40px"}}><Link href={{webPageId: "q3DCJKkcR"}} openInNewTab={false} smoothScroll={false}><motion.a className={"framer-styles-preset-cjeij6"} data-styles-preset={"EDrqc2B6F"}>Prost!</motion.a></Link></motion.p></React.Fragment>} className={"framer-1xt6ma0"} fonts={["CUSTOM;Astonpoliz Regular"]} layoutDependency={layoutDependency} layoutId={"aaHZENtdN"} verticalAlignment={"top"} withExternalLayout/>{isDisplayed() && (<Image background={{alt: "", fit: "fit", pixelHeight: 543, pixelWidth: 355, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/UiRfuEkumc0GQ2SYxZVTsQFCQQk.png", srcSet: "https://framerusercontent.com/images/UiRfuEkumc0GQ2SYxZVTsQFCQQk.png 355w"}} className={"framer-rp2nq5"} layoutDependency={layoutDependency} layoutId={"QY85Rzzok"} {...addPropertyOverrides({"nWQBGlGSq-hover": {background: {alt: "", fit: "fit", pixelHeight: 543, pixelWidth: 355, positionX: "center", positionY: "center", sizes: "26px", src: "https://framerusercontent.com/images/UiRfuEkumc0GQ2SYxZVTsQFCQQk.png", srcSet: "https://framerusercontent.com/images/UiRfuEkumc0GQ2SYxZVTsQFCQQk.png 355w"}}}, baseVariant, gestureVariant)}/>)}</motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FSkOl.framer-1kvgg44, .framer-FSkOl .framer-1kvgg44 { display: block; }", ".framer-FSkOl.framer-11xeq2l { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: auto; justify-content: center; overflow: visible; padding: 0px; position: relative; width: auto; }", ".framer-FSkOl .framer-1xt6ma0 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-FSkOl .framer-rp2nq5 { flex: none; height: 48px; overflow: visible; position: relative; width: 26px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-FSkOl.framer-11xeq2l { gap: 0px; } .framer-FSkOl.framer-11xeq2l > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-FSkOl.framer-11xeq2l > :first-child { margin-left: 0px; } .framer-FSkOl.framer-11xeq2l > :last-child { margin-right: 0px; } }", ".framer-FSkOl.framer-v-11xeq2l.hover .framer-1xt6ma0 { order: 1; }", ".framer-FSkOl.framer-v-11xeq2l.hover .framer-rp2nq5 { order: 0; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 100
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"K_2a5GhPB":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerJcHH_hNXz: React.ComponentType<Props> = withCSS(Component, css, "framer-FSkOl") as typeof Component;
export default FramerJcHH_hNXz;

FramerJcHH_hNXz.displayName = "title";

FramerJcHH_hNXz.defaultProps = {height: 48, width: 100};

addFonts(FramerJcHH_hNXz, [{explicitInter: true, fonts: [{family: "Astonpoliz Regular", source: "custom", url: "https://framerusercontent.com/assets/nkc1Ke9fqKLnxj5xKcEz82hl4.woff2"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})